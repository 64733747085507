<template>
  <div id="myMessageModal" class="white modal">
    <div class="modal-content">
      <h4 class="modal-title"><slot></slot></h4>
      <div class="modal-body font-14">
        <ul>
          <li v-for="(opt, index) in messages" :key="index">{{ opt }}</li>
        </ul>
      </div>
    </div>
    <div class="modal-footer">
      <a
        href="javascript:void(0);"
        @click="closeModal"
        class="modal-close waves-effect waves-green btn-flat white-text red darken-1 "
        >Close</a
      >
    </div>
  </div>
</template>

<script>
import "materialize-css/dist/js/materialize.min";
import M from "materialize-css";

export default {
  name: "MessageModalComponent",
  props: {
    messages: {
      type: Array
    }
  },

  data() {
    return {
      instance: undefined
    };
  },

  methods: {
    initializeModal(options = {}) {
      return M.Modal.init(this.$el, options);
    },
    closeModal() {
      this.instance.close();
    }
  },
  mounted() {
    console.log("component mounted");
    this.instance = this.initializeModal();
    console.log(this.instance);
  },
  watch: {
    messages() {
      console.log("Modal Watcher");

      if (this.messages !== "undefined") {
        console.log("Messages > 0");
        this.instance.open();
      }
    }
  },

  destroyed() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
