<template>
  <div id="confirmModal" class="white modal">
    <div class="modal-content">
      <h6 class="modal-title">Enter you password to confirm this enquiry</h6>

      <div class="input-field vert-offset-top-1 vert-offset-bottom-half">
        <!--  <svg-icon class="mdi prefix" viewbox="0 0 24 24" type="mdi-24" :path="LockIconPath"></svg-icon>-->
        <input
          type="password"
          id="password"
          required="required"
          v-model="password"
          autocomplete="off"
        />
        <label for="password">Password</label>
        <div class="validate col s12">
          <span class="red-text font-11 col s12"
            >Password should have atleast

          </span>

          <span class="red-text font-11 col s12"
            >Password is required.
          </span>

          <span class="red-text font-11 col s12" >
            Password should have less than

          </span>
        </div>
      </div>
      <div class="col s12 ">
        <span class="font-12 black-text darken-4"
          >By clicking confirm I understand & acknowledge that this enquiry will
          attract a charge/service fee</span
        >
      </div>
    </div>
    <div class="modal-footer">
      <a
        href="javascript:void(0);"
        @click.prevent="closeModal"
        class="left btn teal darken-1  white-text modal-close margin-left-1 margin-right-1"
        >Cancel</a
      >

      <a
        href="javascript:void(0);"
        @click.prevent="confirmEnquiryAction"
        class="right btn tealdarken-1   white-text margin-left-1 margin-right-1"
        >Confirm</a
      >
    </div>
  </div>
</template>

<script>
import "materialize-css/dist/js/materialize.min";
import M from "materialize-css";
import { bus } from "../../main";
//import SvgIcon from '@jamescoyle/vue-icon'


export default {
  name: "confirm-modal-with-field-component",
  props: {
    enquiry: {
      type: String
    }
  },
  components: {
    /*SvgIcon*/
  },



  /**
   * @description Component local variables
   * @return {Object} data
   * @return {undefined|FormSelect} data.instance
   */
  data() {
    return {
      instance: undefined,

      password: ""
    };
  },

  methods: {
    /**
     * @description Initialize a new Materialize select component
     * @param {Object} options
     * @return {FormSelect}
     * @see https://materializecss.com/select.html#options
     */
    initializeModal(options = {}) {
      return M.Modal.init(this.$el, options);
    },
    closeModal() {
      console.log("close enquiry");

      this.instance.close();
      bus.$emit("confirmEnquiryAction", "");
    },

    clear() {
      this.password = "";
    },
    confirmEnquiryAction() {
      console.log("confirm enquiry");
      if (this.invalid) {
        console.log("Form Invalid");
      } else {
        console.log("Form valid");
        this.instance.close();
        bus.$emit("confirmEnquiryAction", {
          enquiry_name: this.enquiry,
          password: this.password
        });
        this.clear();
      }
    }
  },
  mounted() {
    console.log("component mounted");
    this.instance = this.initializeModal();
    console.log(this.instance);
  },
  watch: {
    enquiry() {
      console.log("enquiry Name Set to: " + this.enquiry);
      if (this.enquiry != null && this.enquiry.length > 0) {
        this.instance.open();
      }
    }
  }
};
</script>
