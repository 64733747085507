import Vue from "vue";
import Vuex from "vuex";
import http from "../http-common"
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        phone: null,
        status: "",
        Other:[],
        offence:[],
        messages:[],
        user:{},
        processing:0,
        selected_options:null,
        selected_location: null,
        selected_district:null,
        selected_city:null,
        selected_ward:null,
        report_branch:false,
        selected_organisation: null,
        selected_action_status:false,
        selected_action_report:false,
        selected_site: null,
        selected_department: null,
        selected_type: null,
        selected_category: null,
        selected_action:null,
        provided_message: null,
        provided_mobile: null,
        provided_address: null,
        provided_latitude: null,
        provided_longitude: null,
        show_camera: false,
        show_summary: false,
        show_location:false,
        picture: null,
        list_categories:[],
        list_options: [],
        list_locations: [],
        list_types: [],
        list_departments: [],
        contact_form: null

    },
    mutations: {
        initialiseStore(state) {

            if (localStorage.getItem("store")) {
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem("store")))
                );
            }
        },
        AUTH_SUCCESS: (state, token) => {
            state.status = "success";
            state.token = token;
        },
        SET_REPORT_BRANCH: (state, _value) => {
            state.report_branch = _value;
        },
        SELECT_ORGANISATION: (state, _value) => {
            state.selected_organisation = _value;
        },

        SET_SELECTED_SITE: (state, _value) => {
            state.selected_site = _value;
        },
        SET_SELECTED_DEPARTMENT: (state, _value) => {
            state.selected_department = _value;
        },
        SET_SELECTED_CITY: (state, _value) => {
            state.selected_city = _value;
        },
        SET_SELECTED_DISTRICT: (state, _value) => {
            state.selected_district = _value;
        },
        SET_LIST_CATEGORIES: (state, _value) => {
            state.list_categories = _value;
        },
        SET_LIST_OPTIONS: (state, _value) => {
            state.list_options = _value;
        },
        SET_LIST_LOCATIONS: (state, _value) => {
            state.list_locations = _value;
        },
        SET_LIST_TYPES: (state, _value) => {
            state.list_types = _value;
        },
        SET_LIST_DEPARTMENTS: (state, _value) => {
            state.list_departments = _value;
        },
        SET_SELECTED_WARD: (state, _value) => {
            state.selected_ward = _value;
        },
        SET_SELECTED_LOCATION: (state, _value) => {
            state.selected_location = _value;
        },
        SET_SELECTED_ACTION_REPORT: (state, _value) => {
            state.selected_action_report = _value;
        },
        SET_SELECTED_ACTION_STATUS: (state, _value) => {
            state.selected_action_status = _value;
        },
        SET_PROVIDED_LATITUDE: (state, _value) => {
            state.provided_latitude = _value;
        },
        SET_PROVIDED_LONGITUDE: (state, _value) => {
            state.provided_longitude = _value;
        },
        SET_PROVIDED_ADDRESS: (state, _value) => {
            state.provided_address = _value;
        },
        SET_SELECTED_OPTIONS: (state, _value) => {
            state.selected_options = _value;
        },
        SET_SELECTED_TYPE: (state, _value) => {
            state.selected_type = _value;
        },
        SET_SELECTED_CATEGORY: (state, _value) => {
            console.log("1 Select Category " + JSON.stringify(_value));
            state.selected_category = _value;
        },
        SET_PROVIDED_MESSAGE: (state, _value) => {
            state.provided_message = _value;
        },
        SET_PROVIDED_MOBILE: (state, _value) => {
            state.provided_mobile = _value;
        },
        SET_SHOW_CAMERA: (state, _value) => {
            state.show_camera = _value;
        },
        SET_SHOW_SUMMARY: (state, _value) => {
            state.show_summary = _value;
        },
        SET_SHOW_LOCATION: (state, _value) => {
            state.show_location = _value;
        },
        SET_SAVE_PICTURE: (state, _value) => {
            state.picture = _value;
        },
        SET_CONTACT_FORM: (state, _value) => {
            state.contact_form = _value;
        },
        USER: (state, _value) => {
            state.user = _value;
        },
        SET_MESSAGES: (state, _messages) => {
            console.log(JSON.stringify(_messages));
            state.messages = _messages;
        },
        START_PROCESSING: (state) =>
        {
            state.processing++;
            console.log("Processing - Start: "+state.processing);
        },
        STOP_PROCESSING: (state) =>
        {
            if(state.processing > 0)
            {
                state.processing--;
            }
            console.log("Processing - Stop: "+state.processing);
        },
    },
    actions: {

        GET_MESSAGES:({commit},messages)=>
        {
            console.log("SET MESSAGES TO "+JSON.stringify(messages));
            commit('SET_MESSAGES',messages);
        },
        AUTH_REQUEST: ({ commit }, user) => {
            return new Promise(resolve => {
                // The Promise used for router redirect in login
                commit("START_PROCESSING");
                http({ url: "/login", data: user, method: "POST", timeout: 30000 })
                    .then(resp => {
                        commit("STOP_PROCESSING");

                        window.console.log("LOGIN - Response:" + JSON.stringify(resp.data));
                        //console.log("user:"+JSON.stringify(resp.data.data.user));
                        const token = resp.data.data.token;
                        //const userRole = resp.data.data.roles;
                        /*  const userRole = resp.data.data.user.role.data.alias;*/
                        window.console.log("USER DATA: " + JSON.stringify(token));
                        localStorage.setItem("token", token);
                        // localStorage.setItem("role", userRole); // store the token in localstorage // store the token in localstorage
                        /*localStorage.setItem('role', userRole); */ http.defaults.headers.common[
                            "Authorization"
                            ] = "Bearer " + token;
                        commit("USER", resp.data.data.user);
                        commit("AUTH_SUCCESS", token);
                        // commit("USER_ROLE", userRole);
                        resolve(resp);
                    })
                    .catch(response => {
                        commit("STOP_PROCESSING");

                        commit("SET_MESSAGES", response.data.messages);
                        //console.log("MSG"+JSON.stringify(response.data.messages));
                        resolve(response);
                    });
            });
        },
        AUTH_LOGIN: ({ commit }, _token) => {
            commit("AUTH_SUCCESS", _token);
        },
        START_PROCESSING:({commit}) =>
        {
            commit('START_PROCESSING');
        },
        STOP_PROCESSING:({commit}) =>
        {
            commit('STOP_PROCESSING');
        },
        SET_CONTACT_FORM:({commit}, _value)=>
        {
            commit('SET_CONTACT_FORM',_value);
        },
        SET_REPORT_BRANCH:({commit}, _value)=>
        {
            commit('SET_REPORT_BRANCH',_value);
        },
        SET_SELECTED_ORGANISATION:({commit}, _value)=>
        {
            commit('SELECT_ORGANISATION',_value);
        },
        SET_SELECTED_ACTION_REPORT:({commit}, _value)=>
        {
            commit('SET_SELECTED_ACTION_REPORT',_value);
        },
        SET_SELECTED_ACTION_STATUS:({commit}, _value)=>
        {
            commit('SET_SELECTED_ACTION_STATUS',_value);
        },
        SET_SELECTED_OPTIONS:({commit}, _value)=>
        {
            commit('SET_SELECTED_OPTIONS',_value);
        },
        SET_SELECTED_SITE:({commit}, _value)=>
        {
            commit('SET_SELECTED_SITE',_value);
        },
        SET_SELECTED_DEPARTMENT:({commit}, _value)=>
        {
            commit('SET_SELECTED_DEPARTMENT',_value);
        },
        SET_SELECTED_TYPE:({commit}, _value)=>
        {
            commit('SET_SELECTED_TYPE',_value);
        },
        SET_SELECTED_DISTRICT:({commit}, _value)=>
        {
            commit('SET_SELECTED_DISTRICT',_value);
        },
        SET_SELECTED_CITY:({commit}, _value)=>
        {
            commit('SET_SELECTED_CITY',_value);
        },
        SET_SELECTED_WARD:({commit}, _value)=>
        {
            commit('SET_SELECTED_WARD',_value);
        },
        SET_SELECTED_LOCATION:({commit}, _value)=>
        {
            commit('SET_SELECTED_LOCATION',_value);
        },
        SET_PROVIDED_ADDRESS:({commit}, _value)=>
        {
            commit('SET_PROVIDED_ADDRESS',_value);
        },
        SET_PROVIDED_LATITUDE:({commit}, _value)=>
        {
            commit('SET_PROVIDED_LATITUDE',_value);
        },
        SET_PROVIDED_LONGITUDE:({commit}, _value)=>
        {
            commit('SET_PROVIDED_LONGITUDE',_value);
        },
        SET_PROVIDED_MESSAGE:({commit}, _value)=>
        {
            commit('SET_PROVIDED_MESSAGE',_value);
        },
        SET_PROVIDED_MOBILE:({commit}, _value)=>
        {
            commit('SET_PROVIDED_MOBILE',_value);
        },
        SET_SELECTED_CATEGORY:({commit}, _value)=>
        {
            commit('SET_SELECTED_CATEGORY',_value);
        },
        SET_SHOW_CAMERA:({commit}, _value)=>
        {
            commit('SET_SHOW_CAMERA',_value);
        },
        SET_SHOW_SUMMARY:({commit}, _value)=>
        {
            commit('SET_SHOW_SUMMARY',_value);
        },
        SET_SHOW_LOCATION:({commit}, _value)=>
        {
            commit('SET_SHOW_LOCATION',_value);
        },
        SET_SAVE_PICTURE:({commit}, _value)=>
        {
            commit('SET_SAVE_PICTURE',_value);
        },
        SET_LIST_CATEGORIES:({commit}, _value)=>
        {
            commit('SET_LIST_CATEGORIES',_value);
        },
        SET_LIST_OPTIONS:({commit}, _value)=>
        {
            commit('SET_LIST_OPTIONS',_value);
        },
        SET_LIST_LOCATIONS:({commit}, _value)=>
        {
            commit('SET_LIST_LOCATIONS',_value);
        },
        SET_LIST_TYPES:({commit}, _value)=>
        {
            commit('SET_LIST_TYPES',_value);
        },
        SET_LIST_DEPARTMENTS:({commit}, _value)=>
        {
            commit('SET_LIST_DEPARTMENTS',_value);
        },
    },
    modules: {},
    plugins: [
        createPersistedState({
            key: "stored_state",
            reducer(state) {
                //delete state.messages;
                //delete state.processing;

                let reduced_state = {};
                if (state.token !== null || state.token !== 0) {
                    // val.user.token (your user token for example)

                    reduced_state = {
                        user: state.user,
                        token: state.token,
                        status: state.status,
                        selected_organisation: state.selected_organisation,

                    };



                    return reduced_state;
                }


                return reduced_state;
            }
        })
    ]
});
