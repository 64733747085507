import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
      component: () =>
          import("../views/Main.vue")

  },
  {
    path: "/main/:alias",
    name: "Organisation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Organisation_view.vue")
  }
];

const router = new VueRouter({
    mode: 'history',
    duplicateNavigationPolicy: 'ignore',
  routes,
});

export default router;
