import axios from "axios";

export default axios.create({
  baseURL: "https://api.brandboxbw.com"
  /* headers: {

   "http://dev.brandboxbw.com/loan_backend"
        "Content-type": "application/json"
    }*/
});
