<template>
  <div id="myConfirmModal" class="white modal">
    <div class="modal-content">
      <h4 class="modal-title">Are you sure?</h4>
    </div>
    <div class="modal-footer">
      <a
        href="javascript:void(0);"
        @click.prevent="closeModal"
        class="left btn red darken-1  white-text modal-close margin-left-half margin-right-half"
        >NO</a
      >

      <a
        href="javascript:void(0);"
        @click.prevent="confirmAction"
        class="right btn green darken-1  white-text  modal-close margin-left-half margin-right-half"
        >YES</a
      >
    </div>
  </div>
</template>

<script>
import "materialize-css/dist/js/materialize.min";
import M from "materialize-css";
import { bus } from "../../main";

export default {
  name: "confirm-modal-component",
  props: {
    actionName: {
      type: String
    }
  },

  /**
   * @description Component local variables
   * @return {Object} data
   * @return {undefined|FormSelect} data.instance
   */
  data() {
    return {
      instance: undefined
    };
  },

  methods: {
    /**
     * @description Initialize a new Materialize select component
     * @param {Object} options
     * @return {FormSelect}
     * @see https://materializecss.com/select.html#options
     */
    initializeModal(options = {}) {
      return M.Modal.init(this.$el, options);
    },
    closeModal() {
      console.log("close action");

      this.instance.close();
      bus.$emit("confirmAction", "");
    },
    confirmAction() {
      console.log("confirm action");
      bus.$emit("confirmAction", this.actionName);
    }
  },
  mounted() {
    console.log("component mounted");
    this.instance = this.initializeModal();
    console.log(this.instance);
  },
  watch: {
    actionName() {
      console.log("action Name Set to: " + this.actionName);
      if (this.actionName != null && this.actionName.length > 0) {
        this.instance.open();
      }
    }
  }
};
</script>
