<template>
  <div id="app" class=" font-roboto-thin">
    <loading-component></loading-component>
    <message-modal-component :messages="messages"></message-modal-component>
    <confirm-modal-component :actionName="actionName"></confirm-modal-component>
    <confirm-modal-with-field-component
            :enquiry="enquiry"
    ></confirm-modal-with-field-component>


      <!--<div class="col s12">
         <h6 class="text-align-center">AtYourService</h6>
      </div>-->


    <router-view />
  </div>
</template>
<style>
  @import "assets/appsheet.css";
  @import "assets/borders.css";
  @import "assets/loader.css";
  @import "assets/padding.css";
  @import "assets/margin.css";
  @import "assets/password-meter.css";
  @import "assets/style.css";
  @import "assets/vert-offset.css";
  @import "assets/transitions.css";
</style>
<script>
  import http from "./http-common";
  import { mapState } from "vuex";
  import LoadingComponent from "./components/materiallize/LoadingComponent";
  import MessageModalComponent from "./components/materiallize/MessageModalComponent";
  import ConfirmModalComponent from "./components/materiallize/ConfirmModalComponent";
  import ConfirmModalWithFieldComponent from "./components/materiallize/ConfirmModalWithFieldComponent";
  export default {
    components: {
      ConfirmModalComponent,
      MessageModalComponent,
      LoadingComponent,
      ConfirmModalWithFieldComponent,
    },
    methods: {
      goToUrl: function(_url) {
        this.$router.push(_url);
      },


    },

    computed: {
      ...mapState({
        messages: state => state.messages,
        actionName: state => state.actionName,
        enquiry: state => state.enquiry
        /* organisation: state => state.organisations.organisation,
           menu_links : state => state.menu_links,*/
      })
    },
    mounted() {
      http.interceptors.response.use(
              response => {
               /* console.log("* Response"+JSON.stringify(response));*/
                if (response.data.success === false) {
                  return Promise.reject(response);
                }
                return response;
              },
              error => {
                  console.log("* Response"+JSON.stringify(error));

                var response = {
                  data: {
                    messages: (error.data !== 'undefined' && error.data !== null && typeof error.data.messages !== "undefined" && error.data.messages !== null && error.data.messages.length > 0)?error.data.messages:["An error occurred, please try again later."]
                  },
                  message: error.message
                };

                //console.log("* Error Response"+JSON.stringify(error));

                return Promise.reject(response);
              }
      );
    }
  };
</script>
